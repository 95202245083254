import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL, DAYS, MONTHS, STATES, TIMESTEPS } from "../../constants";

import {
  Calendar,
  Modal,
  Button,
  SelectPicker,
  Input,
  Loader,
  DatePicker,
} from "rsuite";
import "./CalendarPage.css";
import Footer from "../../components/Footer";

// Set up axios interceptor to handle 502 errors
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 502) {
      return axios.request(error.config);
    }
    return Promise.reject(error);
  }
);

export default function CalendarPage() {
  const [consultants, setConsultants] = useState();
  const [businessConsultants, setBusinessConsultants] = useState();
  const [availableDates, setAvailableDates] = useState();
  const [selected, setSelected] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [beforModalLoading, setBeforModalLoading] = useState(false);
  const [checkedAll, setCheckedAll] = useState(true);
  const [checkedAllBusiness, setCheckedAllBusiness] = useState(true);
  const [calendarClicked, setCalendarClicked] = useState();
  const [events, setEvents] = useState();
  const [filteredSlots, setFilteredSlots] = useState();

  const [openAvailConsultantsModal, setOpenAvailConsultantsModal] =
    useState(false);
  const [availConsultants, setAvailConsultants] = useState();
  const [selectedConsultant, setSelectedConsultant] = useState();

  const [openFormModal, setOpenFormModal] = useState(false);
  const [availSlots, setAvailSlots] = useState();

  const [form, setForm] = useState({});
  const [invalid, setInvalid] = useState([]);
  const [counsels, setCounsels] = useState({});
  const [counselsIndeces, setCounselsIndeces] = useState([0, 1]);
  const [submitting, setSubmitting] = useState(false);
  const currentMonthFirstDay = new Date().setDate(1);
  const nextMonthDate = new Date(currentMonthFirstDay).setMonth(
    new Date().getMonth() + 1
  );
  const [secondCalendarValue, setSecondCalendarValue] = useState(
    new Date(nextMonthDate)
  );
  const [availSlotsFlag, setAvailSlotsFlag] = useState(true);
  const [limitingEventTime, setLimitingEventTime] = useState(null);
  const [maxDurationSlots, setMaxDurationSlots] = useState([]);
  const [confirmPopup, setConfirmPopup] = useState({});

  const updateConsultantList = (consultantList) => {
    const consultants = [];
    let rodrikDavidUsed = false;

    consultantList.forEach((it) => {
      const consultant = { ...it };
      if (!rodrikDavidUsed && it.name === "Rodrik David") {
        consultant.name = "Mediation NOW";
        rodrikDavidUsed = true;
      }

      consultants.push(consultant);
    });

    return consultants;
  };

  useEffect(() => {
    const secondCalendarButton = document.querySelector(
      ".calendar-hidden-btns .rs-calendar-header-title"
    );
    secondCalendarButton.disabled = true;
    axios
      .get(BASE_URL + "getAllConsultants")
      .then((resp) => {
        const consultants = [];
        let rodrikDavidUsed = false;
        resp?.data?.consultants &&
          // resp.data.consultants.forEach((it) => {
          //   const consultant = { ...it };
          //   if (!rodrikDavidUsed && it.name === "Rodrik David") {
          //     consultant.name = "Mediation NOW";
          //     consultant.order = 9;
          //     rodrikDavidUsed = true;
          //   } else if (it.name === "Regina M. Taylor") {
          //     consultant.order = 8;
          //   } else if (it.name === "Douglas MacKimm") {
          //     consultant.order = 7;
          //   } else if (it.name === "Kevin A. James") {
          //     consultant.order = 6;
          //   } else if (it.name === "Joseph Kaiser") {
          //     consultant.order = 5;
          //   } else if (it.name === "Paul Groth") {
          //     consultant.order = 4;
          //   } else if (it.name === "Pavel Dubenchuk") {
          //     consultant.order = 3;
          //     // } else if (it.name === 'Jay Eidex') {
          //     // 	consultant.order = 2
          //   } else if (it.name === "Pavel Ich1ro") {
          //     consultant.order = 2;
          //     // } else if (it.name === 'Cristopher Welton') {
          //     // 	consultant.order = 1
          //   } else if (it.name === "ich1ro .") {
          //     consultant.order = 1;
          //   } else {
          //     consultant.order = 10;
          //   }

          //   consultants.push(consultant);
          // });
          resp?.data?.consultants.forEach((it) => {
            if(it.hasOwnProperty("order")) {
              if(it.name === "Rodrik David") {
                it.name = "Mediation NOW";
                consultants.push(it);
              } else {
                consultants.push(it);
              }
            } 
          })

        const sortedConsultants = consultants.sort(
          (a, b) => a.order - b.order
        );
        const defaultConsultants = sortedConsultants.filter(
          (consultant) => consultant.name !== "Christopher Welton"
        );
        const sortedBusinessConsultants = sortedConsultants.filter(
          (consultant) => consultant.name === "Christopher Welton"
        );
        setConsultants(defaultConsultants);
        setBusinessConsultants(sortedBusinessConsultants);
      })
      .catch((err) => {
        console.error(err);
      });
      axios.post(BASE_URL + "auth/admin/getAllAvailableDatesPlanned")
      .then((response) => {
        setEvents(response.data.events)
        console.log(response);
      })  
      getAllAvailableDatesByMonth(
        new Date().getFullYear(),
        new Date().getMonth() + 1
      );
  }, []);

  const getAllAvailableDatesByMonth = (year, month) => {
    setCalendarLoading(true);
    axios
      .get(`${BASE_URL}getAllAvailableDatesByMonth?year=${year}&month=${month}`)
      .then((resp) => {
        const secondMonth = month === 12 ? 1 : month + 1;
        const secondYear = month === 12 ? year + 1 : year;
        axios
          .get(
            `${BASE_URL}getAllAvailableDatesByMonth?year=${secondYear}&month=${secondMonth}`
          )
          .then((response) => {
            const arr1 = resp?.data?.dates?.map((it) => {
              const currentDate = new Date();
              const currentFullDate = `${currentDate.getDate()}-${currentDate.getMonth()}-${currentDate.getFullYear()}`;
              const itDate = new Date(it);
              const itFullDate = `${itDate.getDate()}-${itDate.getMonth()}-${itDate.getFullYear()}`;
              if (currentFullDate !== itFullDate) {
                return getDateFromISO(new Date(it)) ?? [];
              }
            });
            const arr2 =
              response?.data?.dates?.map((it) =>
                getDateFromISO(new Date(it))
              ) ?? [];
              
            // axios.post(BASE_URL + "auth/admin/getAllAvailableDatesPlanned")
            //   .then((response) => {
            //     console.log(response);
            //     const allDates = arr1.concat(arr2)
            //     const eventsByDateAndConsultant = response.data.events.reduce((acc, event) => {
            //       const key = event.startTime.split('T')[0];
            //       const consultant_id = event.consultant_id;
                
            //       if (!acc[key]) {
            //         acc[key] = {};
            //       }
                
            //       if (!acc[key][consultant_id]) {
            //         acc[key][consultant_id] = 1;
            //       } else {
            //         acc[key][consultant_id]++;
            //       }
                
            //       return acc;
            //     }, {});
            //     console.log('eventsByDateAndConsultant =====>>>>>', eventsByDateAndConsultant);
            //     axios
            //       .get(BASE_URL + "getAllConsultants")
            //       .then((resp) => {
            //         for (let i = 0; i < allDates.length; i++) {
            //           const date = allDates[i];
                    
            //           for (let j = 0; j < resp.data.consultants.length; j++) {
            //             const consultant = resp.data.consultants[j];
            //             const consultant_id = consultant.consultant_id;
                    
            //             if (eventsByDateAndConsultant[date] && eventsByDateAndConsultant[date][consultant_id] === consultant.mediationsPerDay &&
            //               consultant.mediationsPerDay !== 0) {
            //               allDates.splice(i, 1);
            //               i--;
            //               break;
            //             }
            //           }
            //         }
            //         console.log('allDates ====>>>>>', allDates);
            //       })
                
            //   })
            setAvailableDates(arr1.concat(arr2));
            setCalendarLoading(false);
          })
          .catch((err) => {
            console.error(err);
            setCalendarLoading(false);
          });
      })
      .catch((err) => {
        console.error(err);
        setCalendarLoading(false);
      });
  };

  const getAllAvailableDatesByConsultantIdByMonth = async (ids, year, month) => {
    setCalendarLoading(true);

    const stringifiedIds = JSON.stringify(ids);

    axios
      .get(
        `${BASE_URL}getAllAvailableDatesByConsultantIdByMonth?consultant_ids=${stringifiedIds}&year=${year}&month=${month}`
      )
      .then((resp) => {
        const secondMonth = month === 12 ? 1 : month + 1;
        const secondYear = month === 12 ? year + 1 : year;
        axios
          .get(
            `${BASE_URL}getAllAvailableDatesByConsultantIdByMonth?consultant_ids=${stringifiedIds}&year=${secondYear}&month=${secondMonth}`
          )
          .then((response) => {
            const arr1 = resp?.data?.dates?.map((it) => {
              const currentDate = new Date();
              const currentFullDate = `${currentDate.getDate()}-${currentDate.getMonth()}-${currentDate.getFullYear()}`;
              const itDate = new Date(it);
              const itFullDate = `${itDate.getDate()}-${itDate.getMonth()}-${itDate.getFullYear()}`;
              if (currentFullDate !== itFullDate) {
                return getDateFromISO(new Date(it)) ?? [];
              }
            });
            const arr2 =
              response?.data?.dates?.map((it) =>
                getDateFromISO(new Date(it))
              ) ?? [];
              const allDates = arr1.concat(arr2)
              // axios.post(BASE_URL + "auth/admin/getAllAvailableDatesPlanned")
              // .then(async (response) => {
              //   if(events.length > 0) {
              //     const eventsByDateAndConsultant = response.data.events.reduce((acc, event) => {
              //       const key = event.startTime.split('T')[0];
              //       const consultant_id = event.consultant_id;
                  
              //       if (!acc[key]) {
              //         acc[key] = {};
              //       }
                  
              //       if (!acc[key][consultant_id]) {
              //         acc[key][consultant_id] = 1;
              //       } else {
              //         acc[key][consultant_id]++;
              //       }
                  
              //       return acc;
              //     }, {});
              //     console.log('eventsByDateAndConsultant: ', eventsByDateAndConsultant);
              //     for (let i = 0; i < allDates.length; i++) {
              //       const date = allDates[i];
                    
              //         if (
              //           eventsByDateAndConsultant[date] &&
              //           eventsByDateAndConsultant[date][id] === mediationsCount &&
              //           mediationsCount !== 0
              //         ) {
              //           allDates.splice(i, 1);
              //           i--
              //         }
              //     }
              //     const currentDate = new Date()
              //     const tomorrowDate = new Date()
              //     tomorrowDate.setDate(currentDate.getDate() + 1)
  
              //     const filteredDates = allDates.filter(date => {
              //       if(date) {
              //         const dateParts = date.split('-').map(Number);
              //         const dateObject = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    
              //         return dateObject >= currentDate
              //       }
              //     });
  
              //     console.log('filteredDates: ', filteredDates);
  
              //     const filteredAllDates = []
  
              //     // filteredDates.forEach(date => {
              //     //   if(date) {
              //     //     const dateParts = date.split('-').map(Number);
              //     //     const dateObject = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
  
              //     //     axios.get(`${BASE_URL}getConsultantSlotsByDate?consultant_id=${id}&date=${dateObject.toISOString()}`)
              //     //     .then(resp => {
              //     //       if(resp?.data?.slots?.length >= 7) {
              //     //         filteredAllDates.push(date)
              //     //       }
              //     //     }).catch(error => {
              //     //       console.error(`Error fetching slots for ${date}:`, error);
              //     //     });
              //     //   }
              //     // })
  
              //     const fetchDataAndFilter = async (date) => {
              //       const currentDate = new Date(date)
              //       const currentTimeZoneOffset = currentDate.getTimezoneOffset() * 60000
                    
              //       const changeDate = new Date()
              //       if(currentTimeZoneOffset >= 0) {
              //         changeDate.setDate(
              //           currentDate.getDate() + 1
              //         )
              //         } else {
              //           changeDate.setDate(
              //             currentDate.getDate()
              //           )
              //         }
              //         changeDate.setMonth(
              //           currentDate.getMonth(),
              //           )
              //       const dateInCurrent = new Date(changeDate.getTime() - currentTimeZoneOffset);
              //       const localDate = new Date(changeDate.getTime() - (changeDate.getTimezoneOffset() * 60000));
              //       const formattedDate = localDate.toISOString();
              //       return new Promise(async (resolve) => {
              //         if (date && events.length !== 0) {
              //           const hasEvents = events.some(event => {
              //             const eventDate = event.startTime.split('T')[0]
              //             return eventDate === date;
              //           });
  
              //           if (hasEvents) {
              //             const getDate = new Date(date)
              //             const getLocalDate = new Date(getDate.getTime() - (getDate.getTimezoneOffset() * 60000)).toISOString();
              //             console.log('dateInCurrent.toISOString >>>>', dateInCurrent.toISOString());
              //             console.log('changeDate.toISOString() >>>>', changeDate.toISOString());
              //             const dateInCurrentTime = dateInCurrent.getHours() + 5
              //             console.log('dateInCurrentTime >>>>', dateInCurrentTime);
              //             try {
              //               await axios.get(`${BASE_URL}getConsultantSlotsByDate?consultant_id=${id}&date=${dateInCurrentTime >= 10 ? dateInCurrent.toISOString() : changeDate.toISOString()}`)
              //               .then(resp => {
              //                 let filteredSlots = resp?.data?.slots
              //                 const bufferTime = 3.5 * 60 * 60 * 1000;
  
              //                 const filteredEvents = response?.data?.events.filter(event => {
              //                 const eventDate = event.startTime.split('T')[0];
                  
              //                 return event.consultant_id === id && eventDate === date;
              //                 })
              //                 if(filteredEvents?.length){
              //                   filteredEvents.forEach(event => {
              //                     const eventStart = new Date(event.startTime);
              //                     const timezoneOffset = eventStart.getTimezoneOffset() * 60000;
              //                     const dateInCurrentTimeZone = new Date(eventStart.getTime() + timezoneOffset);
              //                     const durationParts = event.duration.split(':');
  
              //                     const durationInMilliseconds = (parseInt(durationParts[0], 10) * 60 + parseInt(durationParts[1], 10)) * 60 * 1000;
              //                     const eventEnd = new Date(dateInCurrentTimeZone.getTime() + durationInMilliseconds);
              //                     console.log('eventEnd >>>>', eventEnd);
                    
              //                     filteredSlots = filteredSlots.filter(time => {
              //                       const currentTime = new Date(time);
              //                       return currentTime.getTime() >= eventEnd.getTime() + bufferTime || currentTime.getTime() < dateInCurrentTimeZone.getTime() - bufferTime;
              //                     });
              //                   })
              //                   console.log('filteredSlots >>>>>>>>>>', filteredSlots)
              //                 }
              //                 if (filteredSlots?.length >= 7) {
              //                   filteredAllDates.push(date);
              //                 }
              //               }).catch(err => {
              //                 console.error(err);
              //               }) 
              //             } catch (error) {
              //               console.error(`Error fetching slots for ${date}:`, error);
              //             }
              //           } else {
              //             filteredAllDates.push(date);
              //           }
              //         } else {
              //           filteredAllDates.push(date);
              //         }
                  
              //         resolve();
              //       });
              //     };
                  
              //     const fetchPromises = filteredDates.map(date => fetchDataAndFilter(date));
                  
              //     await Promise.all(fetchPromises);
                  
              //     const compareDates = (a, b) => {
              //       const dateA = new Date(a);
              //       const dateB = new Date(b);
                  
              //       return dateA - dateB;
              //     };
                  
              //     const sortedDates = filteredAllDates.sort(compareDates);
              //     setAvailableDates(sortedDates);
              //     setCalendarLoading(false);
              //   } else {
              //     setAvailableDates(allDates);
              //     setCalendarLoading(false);
              //   }
              // })
              // let filteredDates = []
              // for(let i = 0; i < allDates?.length; i++) {
              //   const date = allDates[i];
              //   axios.get(BASE_URL + `getConsultantSlotsByDate?consultant_id=${id}&date=${date}`).then((response) => {
              //     if(response.slots?.length > 6) {
              //       filteredDates.push(date)
              //     }
              //   })
              // }
              // const compareDates = (a, b) => {
              //   const dateA = new Date(a);
              //   const dateB = new Date(b);
              
              //   return dateA - dateB;
              // };

              // const sortedDates = filteredDates?.sort(compareDates)

              // if (sortedDates?.length > 0) {
              //   setAvailableDates(sortedDates)
              //   setCalendarLoading(false);
              // } else {
              //   setAvailableDates(allDates);
              //   setCalendarLoading(false);
              // }
              setAvailableDates(allDates);
                setCalendarLoading(false);
          })
          .catch((err) => {
            console.error(err);
            setCalendarLoading(false);
          });
      })
      .catch((err) => {
        console.error(err);
        setCalendarLoading(false);
      });
  };

  const select = (value, it) => {
    setCalendarLoading(true);

    if (it === "all") {
      setCheckedAll(true);
      getAllAvailableDatesByMonth(
        (selectedDate ?? new Date()).getFullYear(),
        (selectedDate ?? new Date()).getMonth() + 1
      );
      setSelected([]);
    } else {
      setCheckedAll(false);
    }

    if (it !== "all") {
      setSelected((prevState) => {
        const isExisted = prevState.some((item) => item.consultant_id === it.consultant_id);

        if (!isExisted) {
          return [...prevState, it];
        } 

        return prevState.filter((item) => item.consultant_id !== it.consultant_id);
      });
    }

    if (!value && selected.length === 1) {
      setCheckedAll(true);
      getAllAvailableDatesByMonth(
        (selectedDate ?? new Date()).getFullYear(),
        (selectedDate ?? new Date()).getMonth() + 1
      );
      setSelected([]);
    }
  };

  useEffect(() => {
    if (selected.length) {
      const consultantIds = selected.map((it) => it.consultant_id);

      getAllAvailableDatesByConsultantIdByMonth(
        consultantIds,
        (selectedDate ?? new Date()).getFullYear(),
        (selectedDate ?? new Date()).getMonth() + 1,
      );
    }
  }, [selected, selectedDate]);

  const renderCell = (date, calendarIndex) => {
    if (calendarIndex === 1 && (calendarClicked === 1 || !calendarClicked)) {
      if (date.getMonth() !== (selectedDate ?? new Date()).getMonth()) {
        return (
          <div
            className={
              "calendar-cell another-month-cb" +
              (getDateFromISO(date) === getDateFromISO(new Date())
                ? " cb-today"
                : "")
            }
          >
            {getDay(date)}
          </div>
        );
      } else if (
        !availableDates?.includes(getDateFromISO(date)) ||
        date.getMonth() < new Date().getMonth() ||
        (date.getMonth() === new Date().getMonth() &&
          date.getDate() < new Date().getDate())
      ) {
        return (
          <div
            className={
              "calendar-cell non-available-cb" +
              (getDateFromISO(date) === getDateFromISO(new Date())
                ? " cb-today"
                : "")
            }
          >
            {getDay(date)}
          </div>
        );
      } else {
        return (
          <div
            className={
              "calendar-cell available-cb" +
              (getDateFromISO(date) === getDateFromISO(new Date())
                ? " cb-today"
                : "")
            }
          >
            {getDay(date)}
          </div>
        );
      }
    } else if (
      calendarIndex === 2 &&
      (calendarClicked === 1 || !calendarClicked)
    ) {
      if (new Date().getMonth() === 11) {
        if (
          (date.getMonth() + 1) !== 1 &&
          calendarIndex === 2
        ) {
          return (
            <div
              className={
                "calendar-cell another-month-cb" +
                (getDateFromISO(date) === getDateFromISO(new Date())
                  ? " cb-today"
                  : "")
              }
            >
              {getDay(date)}
            </div>
          );
        } else if (
          !availableDates?.includes(getDateFromISO(date))
        ) {
          return (
            <div
              className={
                "calendar-cell non-available-cb" +
                (getDateFromISO(date) === getDateFromISO(new Date())
                  ? " cb-today"
                  : "")
              }
            >
              {getDay(date)}
            </div>
          );
        } else {
          return (
            <div
              className={
                "calendar-cell available-cb" +
                (getDateFromISO(date) === getDateFromISO(new Date())
                  ? " cb-today"
                  : "")
              }
            >
              {getDay(date)}
            </div>
          );
        }
      } else {
        if (
          date.getMonth() !== (selectedDate ?? new Date()).getMonth() + 1 &&
          calendarIndex === 2
        ) {
          return (
            <div
              className={
                "calendar-cell another-month-cb" +
                (getDateFromISO(date) === getDateFromISO(new Date())
                  ? " cb-today"
                  : "")
              }
            >
              {getDay(date)}
            </div>
          );
        } else if (
          !availableDates?.includes(getDateFromISO(date)) ||
          date.getMonth() < new Date().getMonth() ||
          (date.getMonth() === new Date().getMonth() &&
            date.getDate() < new Date().getDate())
        ) {
          return (
            <div
              className={
                "calendar-cell non-available-cb" +
                (getDateFromISO(date) === getDateFromISO(new Date())
                  ? " cb-today"
                  : "")
              }
            >
              {getDay(date)}
            </div>
          );
        } else {
          return (
            <div
              className={
                "calendar-cell available-cb" +
                (getDateFromISO(date) === getDateFromISO(new Date())
                  ? " cb-today"
                  : "")
              }
            >
              {getDay(date)}
            </div>
          );
        }
      }
    } else if (
      calendarIndex === 2 &&
      (calendarClicked === 2 || !calendarClicked)
    ) {
      if (new Date().getMonth() === 11) {
        if (
          date.getMonth() + 1 !== 1  &&
          calendarIndex === 2
        ) {
          return (
            <div
              className={
                "calendar-cell another-month-cb" +
                (getDateFromISO(date) === getDateFromISO(new Date())
                  ? " cb-today"
                  : "")
              }
            >
              {getDay(date)}
            </div>
          );
        } else if (
          !availableDates?.includes(getDateFromISO(date))
        ) {
          return (
            <div
              className={
                "calendar-cell non-available-cb" +
                (getDateFromISO(date) === getDateFromISO(new Date())
                  ? " cb-today"
                  : "")
              }
            >
              {getDay(date)}
            </div>
          );
        } else {
          return (
            <div
              className={
                "calendar-cell available-cb" +
                (getDateFromISO(date) === getDateFromISO(new Date())
                  ? " cb-today"
                  : "")
              }
            >
              {getDay(date)}
            </div>
          );
        }
      } else {
        if (
          date.getMonth() + 1 !== (selectedDate ?? new Date()).getMonth() + 1 &&
          calendarIndex === 2
        ) {
          return (
            <div
              className={
                "calendar-cell another-month-cb" +
                (getDateFromISO(date) === getDateFromISO(new Date())
                  ? " cb-today"
                  : "")
              }
            >
              {getDay(date)}
            </div>
          );
        } else if (
          !availableDates?.includes(getDateFromISO(date)) ||
          date.getMonth() < new Date().getMonth() ||
          (date.getMonth() === new Date().getMonth() &&
            date.getDate() < new Date().getDate())
        ) {
          return (
            <div
              className={
                "calendar-cell non-available-cb" +
                (getDateFromISO(date) === getDateFromISO(new Date())
                  ? " cb-today"
                  : "")
              }
            >
              {getDay(date)}
            </div>
          );
        } else {
          return (
            <div
              className={
                "calendar-cell available-cb" +
                (getDateFromISO(date) === getDateFromISO(new Date())
                  ? " cb-today"
                  : "")
              }
            >
              {getDay(date)}
            </div>
          );
        }
      }
    } else if (
      calendarIndex === 1 &&
      (calendarClicked === 2 || !calendarClicked)
    ) {
      if (new Date().getMonth() === 11) {
        if (date.getMonth() !== 11) {
          return (
            <div
              className={
                "calendar-cell another-month-cb" +
                (getDateFromISO(date) === getDateFromISO(new Date())
                  ? " cb-today"
                  : "")
              }
            >
              {getDay(date)}
            </div>
          );
        } else if (
          !availableDates?.includes(getDateFromISO(date)) ||
          date.getMonth() < new Date().getMonth() ||
          (date.getMonth() === new Date().getMonth() &&
            date.getDate() < new Date().getDate())
        ) {
          return (
            <div
              className={
                "calendar-cell non-available-cb" +
                (getDateFromISO(date) === getDateFromISO(new Date())
                  ? " cb-today"
                  : "")
              }
            >
              {getDay(date)}
            </div>
          );
        } else {
          return (
            <div
              className={
                "calendar-cell available-cb" +
                (getDateFromISO(date) === getDateFromISO(new Date())
                  ? " cb-today"
                  : "")
              }
            >
              {getDay(date)}
            </div>
          );
        }
      } else {
        if (date.getMonth() + 1 !== (selectedDate ?? new Date()).getMonth()) {
          return (
            <div
              className={
                "calendar-cell another-month-cb" +
                (getDateFromISO(date) === getDateFromISO(new Date())
                  ? " cb-today"
                  : "")
              }
            >
              {getDay(date)}
            </div>
          );
        } else if (
          !availableDates?.includes(getDateFromISO(date)) ||
          date.getMonth() < new Date().getMonth() ||
          (date.getMonth() === new Date().getMonth() &&
            date.getDate() < new Date().getDate())
        ) {
          return (
            <div
              className={
                "calendar-cell non-available-cb" +
                (getDateFromISO(date) === getDateFromISO(new Date())
                  ? " cb-today"
                  : "")
              }
            >
              {getDay(date)}
            </div>
          );
        } else {
          return (
            <div
              className={
                "calendar-cell available-cb" +
                (getDateFromISO(date) === getDateFromISO(new Date())
                  ? " cb-today"
                  : "")
              }
            >
              {getDay(date)}
            </div>
          );
        }
      }
    }
  };

  const cellSelect = (date, calendarIndx) => {
    if (calendarIndx === 1) {
      setCalendarClicked(1);
      const currentMonthDay = new Date(date).setDate(1);
      const nextMonth = new Date(currentMonthDay).setMonth(
        new Date(currentMonthDay).getMonth() + 1
      );
      setSecondCalendarValue(new Date(nextMonth));
    } else {
      setCalendarClicked(2);
    }

    setSelectedDate(date);

    setBeforModalLoading(true);
    if (
      availableDates?.includes(getDateFromISO(date))
    ) {

      if (selected.length === 1) {
        setSelectedConsultant(selected[0]);
        getConsultantSlotsByDate(selected[0].consultant_id, date.toISOString(), true);
      } else {
        setOpenAvailConsultantsModal(true);
        const consultantIds = selected.length ? selected.map((it) => it.consultant_id) : consultants.map((it) => it.consultant_id);
        axios
          .get(
            `${BASE_URL}getAllAvailableConsultantsByDate?date=${date.toISOString()}&consultant_ids=${JSON.stringify(consultantIds)}`
          )
          .then((resp) => {
            const consultants = [];
            resp?.data?.consultants &&
              resp.data.consultants.forEach((it) => {
                const consultant = { ...it, name: it.fullName };
                      consultants.push(consultant);
              });
              axios
                .get(BASE_URL + "getAllConsultants")
                .then(res => {
                  const consultantsArr = res?.data?.consultants || []
                  const combinedConsultants = consultants.map((consultantWithName) => {
                    const consultantWithMediations = consultantsArr.find(
                      (c) => c.consultant_id === consultantWithName.id
                    );
                  
                    return {
                      ...consultantWithName,
                      mediationsPerDay: consultantWithMediations ? consultantWithMediations.mediationsPerDay : 0,
                    };
                  })

                  const availableConsultants = combinedConsultants.filter(consultant => {
                    const eventsForConsultant = events?.filter(event => event.consultant_id === consultant.id && event.startTime.includes(getDateFromISO(date))) || [];
                    return consultant.mediationsPerDay === 0 || (consultant.mediationsPerDay > 0 && eventsForConsultant.length < consultant.mediationsPerDay) || consultant.mediationsPerDay < eventsForConsultant.length}
                  );

                  const filteredAvailableConsultants = []
                  
                  availableConsultants.forEach(async (consultant) => {
                    await axios.get(BASE_URL + `getConsultantSlotsByDate?consultant_id=${consultant.id}&date=${date.toISOString()}`).then(resp => {
                      if(resp?.data?.slots?.length > 7) {
                        filteredAvailableConsultants.push(consultant)
                      }
                    })
                  })
                  
                  filteredAvailableConsultants.forEach((consultant) => {
                    if(consultant.name === 'Rodrik David') {
                      consultant.name = 'Mediation NOW'
                    }
                  })

                  setAvailConsultants(filteredAvailableConsultants);

                  console.log(filteredAvailableConsultants);
                  setTimeout(() => {
                    setBeforModalLoading(false);
                  }, 3000)
                })
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  const cellChange = (date, calendarIndx) => {
    if (calendarIndx === 1) {
      setCalendarClicked(1);
      const currentMonthDay = new Date(date).setDate(1);
      const nextMonth = new Date(currentMonthDay).setMonth(
        new Date(currentMonthDay).getMonth() + 1
      );
      setSecondCalendarValue(new Date(nextMonth));
    } else {
      setCalendarClicked(2);
    }

    setSelectedDate(date);

    setBeforModalLoading(true);
    if (
      availableDates?.includes(getDateFromISO(date))
    ) {
      if (!selected.length) {
        setOpenAvailConsultantsModal(true);
        const consultantIds = consultants.map((it) => it.consultant_id);
        axios
          .get(
            `${BASE_URL}getAllAvailableConsultantsByDate?date=${date.toISOString()}&consultant_ids=${JSON.stringify(consultantIds)}`
          )
          .then((resp) => {
            const consultants = [];
            resp?.data?.consultants &&
              resp.data.consultants.forEach((it) => {
                const consultant = { ...it, name: it.fullName };

                consultants.push(consultant);
            });
              axios
              .get(BASE_URL + "getAllConsultants")
              .then(res => {
                const consultantsArr = res?.data?.consultants || []
                const combinedConsultants = consultants.map((consultantWithName) => {
                  const consultantWithMediations = consultantsArr.find(
                    (c) => c.consultant_id === consultantWithName.id
                  );
                
                  return {
                    ...consultantWithName,
                    mediationsPerDay: consultantWithMediations ? consultantWithMediations.mediationsPerDay : 0,
                  };
                })

                const availableConsultants = combinedConsultants.filter(consultant => {
                  const eventsForConsultant = events?.filter(event => event.consultant_id === consultant.id && event.startTime.includes(getDateFromISO(date))) || [];
                  return consultant.mediationsPerDay === 0 || (consultant.mediationsPerDay > 0 && eventsForConsultant.length < consultant.mediationsPerDay) || consultant.mediationsPerDay < eventsForConsultant.length}
                );

                const filteredAvailableConsultants = []

                availableConsultants.forEach(async (consultant) => {
                  await axios.get(BASE_URL + `getConsultantSlotsByDate?consultant_id=${consultant.id}&date=${date.toISOString()}`).then(resp => {
                    if(resp?.data?.slots?.length > 7) {
                      filteredAvailableConsultants.push(consultant)
                    }
                  })
                })

                filteredAvailableConsultants.forEach((consultant) => {
                  if(consultant.name === 'Rodrik David') {
                    consultant.name = 'Mediation NOW'
                  }
                })

                setAvailConsultants(filteredAvailableConsultants);

                console.log(filteredAvailableConsultants);
                setTimeout(() => {
                  setBeforModalLoading(false);
                }, 3000)
              })
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        setSelectedConsultant(selected[0]);
        getConsultantSlotsByDate(selected[0].consultant_id, date.toISOString(), false);
      }
    }
  };

  const cellMonthChange = async (date, calendarIndx) => {
    setSelectedDate(date);
    onAvailConsultantClose();
    // second calendar change value
    const currentMonthDay = new Date(date).setDate(1);
    const nextMonth = new Date(currentMonthDay).setMonth(
      new Date(currentMonthDay).getMonth() + 1
    );
    setSecondCalendarValue(new Date(nextMonth));
    

    if (
      date.getMonth() >= new Date().getMonth() - 1 &&
      date.getFullYear() >= new Date().getFullYear() - 1
    ) {
      if (selected.length === 1) {
        await getAllAvailableDatesByConsultantIdByMonth(
          [selected[0].consultant_id],
          date.getFullYear(),
          date.getMonth() + 1,
        );
      } else {
        await getAllAvailableDatesByMonth(
          date.getFullYear(),
          date.getMonth() + 1
        );
      }
    }
  };

  const getDay = (date) => {
    let day = date?.getDate();
    day < 10 && (day = "0" + day);
    return day;
  };

  const toISOUtc = (date, zeros) => {
    let tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? "+" : "-",
      pad = function (num) {
        return (num < 10 ? "0" : "") + num;
      };

    return (
      date.getFullYear() +
      "-" +
      pad(date.getMonth() + 1) +
      "-" +
      pad(date.getDate()) +
      "T" +
      pad(date.getHours()) +
      ":" +
      pad(zeros ? 0 : date.getMinutes()) +
      ":" +
      pad(zeros ? 0 : date.getSeconds()) +
      dif +
      pad(zeros ? 0 : Math.floor(Math.abs(tzo) / 60)) +
      ":" +
      pad(zeros ? 0 : Math.abs(tzo) % 60)
    );
  };

  const getDateFromISO = (date) => {
    return toISOUtc(date).split("T")[0];
  };

  const getTextDate = (date) => {
    const day = getDay(date);
    const month = MONTHS[date?.getUTCMonth()];
    const year = date?.getFullYear();
    const weekDay = DAYS[date?.getDay()];

    return `${weekDay}, ${month} ${day} ${year}`;
  };

  const onAvailConsultantClose = (selectedAvaiable) => {
    setOpenAvailConsultantsModal(false);
    setSelectedConsultant(selectedAvaiable);

    if (selectedAvaiable) {
      getConsultantSlotsByDate(
        selectedAvaiable?.id,
        selectedDate.toISOString(),
        true
      );
    }
  };

  const getConsultantSlotsByDate = (consultantId, date, openModal) => {
    axios
      .get(
        `${BASE_URL}getConsultantSlotsByDate?consultant_id=${consultantId}&date=${date}`
      )
      .then((resp) => {
        getSlotsForSelect(resp?.data?.slots, consultantId, date);
        setLimitingEventTime(resp?.data?.limitingEventTime);
        console.log(resp?.data?.slots)
        setAvailSlots(resp?.data?.slots ?? []);
        setCounsels({ 0: {}, 1: {} });
        openModal && setOpenFormModal(true);
        setBeforModalLoading(true);
      })
      .catch((err) => {
        console.error(err);
        setBeforModalLoading(true);
      });
  };

  const onFormClose = () => {
    setOpenFormModal(false);
    setTimeout(() => {
      setForm({});
      setCounsels({});
      setCounselsIndeces([0, 1]);
      setSelectedConsultant(undefined);
      setSubmitting(false);
      setInvalid([]);
      setFilteredSlots([])
      setMaxDurationSlots([])
    }, 300);
  };

  const calculateMaxTimeDuration = (selectedTime) => {
    if (!selectedTime) return null;

    const lastSlotHour = Number.parseInt(
      availSlots[availSlots.length - 1].slice(11, 13)
    );
    const lastSlotMinutes = Number.parseInt(
      availSlots[availSlots.length - 1].slice(14, 17)
    );
    let index = availSlots.findIndex((item) => item === selectedTime);
    const newArr = [];

    let isSelectedTimeWithouGaps = true;

    for (index; index < availSlots.length; index++) {
      const element = availSlots[index];
      const nextElement = availSlots[index + 1];
      if (!nextElement) {
        break;
      }
      if (
        Number.parseInt(element.slice(11, 13)) !==
          Number.parseInt(nextElement.slice(11, 13)) &&
        element.slice(13, 17) === nextElement.slice(13, 17)
      ) {
        isSelectedTimeWithouGaps = false;
      } else if (
        Number.parseInt(element.slice(11, 13)) + 1 <
          Number.parseInt(nextElement.slice(11, 13)) &&
        element.slice(13, 17) !== nextElement.slice(13, 17)
      ) {
        isSelectedTimeWithouGaps = false;
      }
    }

    if (limitingEventTime && isSelectedTimeWithouGaps) {
      const limitingEventTimeHours = Number.parseInt(
        limitingEventTime.slice(11, 13)
      );
      let limitingEventTimeMinutes = Number.parseInt(
        limitingEventTime.slice(14, 17)
      );
      let pevot = lastSlotHour;

      while (pevot < limitingEventTimeHours) {
        if (
          limitingEventTimeHours > lastSlotHour &&
          limitingEventTimeMinutes === lastSlotMinutes
        ) {
          newArr.push(1, 1);
        } else if (limitingEventTimeMinutes !== lastSlotMinutes) {
          newArr.push(1);
          limitingEventTimeMinutes = lastSlotMinutes;
        }
        pevot++;
      }
    }

    let calcIndex = availSlots.findIndex((item) => item === selectedTime);
    for (calcIndex; calcIndex < availSlots.length; calcIndex++) {
      const element = availSlots[calcIndex];
      const nextElement = availSlots[calcIndex + 1];

      if (!nextElement) {
        newArr.push(element);
        break;
      } else if (element.slice(11, 13) === nextElement.slice(11, 13)) {
        newArr.push(element);
      } else if (
        Number.parseInt(element.slice(11, 13)) + 1 ===
          Number.parseInt(nextElement.slice(11, 13)) &&
        element.slice(13, 17) !== nextElement.slice(13, 17)
      ) {
        newArr.push(element);
      } else if (
        Number.parseInt(element.slice(11, 13)) + 1 ===
          Number.parseInt(nextElement.slice(11, 13)) &&
        element.slice(13, 17) === nextElement.slice(13, 17)
      ) {
        newArr.push(element);
        break;
      } else {
        newArr.push(element);
        break;
      }
    }
    if (selectedConsultant?.name === "Mediation NOW") {
      newArr.splice(0, 5);
      setMaxDurationSlots(
        TIMESTEPS.filter((e, index) =>
          index < newArr.length && index > 1 ? e : null
        )
      );
    } else {
      newArr.splice(0, 3);
      setMaxDurationSlots(
        TIMESTEPS.filter((e, index) => (index < newArr.length ? e : null))
      );
    }
    setAvailSlotsFlag((e) => !e);
  };

  useEffect(() => {
    console.log(filteredSlots);
  }, [availSlots, selectedConsultant, selectedDate]);

  const getSlotsForSelect = async (slots, id, date) => {
    console.log('slots', slots);
    console.log('date', date);
    if(date) {
      const getDate = new Date(date)
      const localDate = new Date(getDate.getTime() - (getDate.getTimezoneOffset() * 60000));
      const formattedDate = localDate.toISOString().split('T')[0];
      if(slots?.length > 0) {
        let filteredSlots = slots
        const bufferTime = 3.5 * 60 * 60 * 1000;

        await axios.post(BASE_URL + 'auth/admin/getAllAvailableDatesPlanned').then((res) => {
          if(res?.data?.events?.length > 0) {
            const filteredEvents = res?.data?.events.filter(event => {
              const eventDate = event.startTime.split('T')[0];
              return event.consultant_id === id && eventDate === formattedDate;
            });

            if(filteredEvents?.length){
              filteredEvents.forEach(event => {
                
                const eventStart = new Date(event.startTime);
                const timezoneOffset = eventStart.getTimezoneOffset() * 60000;
                const dateInCurrentTimeZone = new Date(eventStart.getTime() + timezoneOffset);

                const durationParts = event.duration.split(':');
                const durationInMilliseconds = (parseInt(durationParts[0], 10) * 60 + parseInt(durationParts[1], 10)) * 60 * 1000;
                const eventEnd = new Date(dateInCurrentTimeZone.getTime() + durationInMilliseconds);
                console.log('event.startTime: ', event.startTime);
                console.log('eventStart.getTimezoneOffset(): ', eventStart.getTimezoneOffset());
                console.log('dateInCurrentTimeZone: ', dateInCurrentTimeZone);
                console.log('durationInMilliseconds: ', durationInMilliseconds);
                console.log('eventEnd: ', eventEnd);
  
                filteredSlots = filteredSlots.filter(time => {
                  const currentTime = new Date(time);
                  return currentTime.getTime() >= eventEnd.getTime() + bufferTime || currentTime.getTime() < dateInCurrentTimeZone.getTime() - bufferTime;
                });
              })
              console.log('filteredSlots >>>>>>>>>>', filteredSlots)
            }
          }
        })

        const slotsForSelector = filteredSlots.map((date) => {
          let hours = new Date(date).getHours();
          let minutes = new Date(date).getMinutes();
          let ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          let strTime = hours + ":" + minutes + " " + ampm;
          return { label: strTime, value: date };
        })

        await setFilteredSlots(slotsForSelector)

        console.log('slotsForSelector', slotsForSelector);
      }
    }
  };

  const submitForm = (e) => {
    e.preventDefault();

    const councelArray = [];
    Object.entries(counsels).forEach((entry) => {
      councelArray.push(entry[1]);
    });

    const formToSend = { ...form };
    formToSend.consultant_id = selectedConsultant?.consultant_id || selectedConsultant?.id;
    formToSend.caseInformation || (formToSend.caseInformation = {});
    formToSend?.caseInformation?.courtType ||
      (formToSend.caseInformation.courtType = "Voluntary");
    formToSend.counsels = councelArray;
    if (formToSend?.caseInformation?.courtType === "Court Ordered") {
      formToSend?.caseInformation?.courtOrdered
        ? (formToSend.caseInformation.courtOrdered =
            formToSend.caseInformation.courtOrdered.toISOString())
        : (formToSend.caseInformation.courtOrdered =
            selectedDate.toISOString());
    }

    const validationResult = validateForm(formToSend);

    if (validationResult === true) {
      setSubmitting(true);
      axios
        .post(BASE_URL + "scheduleEventWithConsultantByDate", formToSend)
        .then(async () => {
          setOpenFormModal(false);
          setForm({});
          setCounsels({});
          setCounselsIndeces([0, 1]);
          setSubmitting(false);
          setConfirmPopup({
            type: "eventSuccess",
            startTime: formToSend.startTime,
            consultantName: selectedConsultant.name,
          });
          if (selected.length) {
            getAllAvailableDatesByConsultantIdByMonth(
              [selected[0].consultant_id],
              (selectedDate ?? new Date()).getFullYear(),
              (selectedDate ?? new Date()).getMonth() + 1,
              selectedConsultant?.mediationsPerDay
            );
          } else {
            getAllAvailableDatesByMonth(
              (selectedDate ?? new Date()).getFullYear(),
              (selectedDate ?? new Date()).getMonth() + 1
            );
          }
        })
        .catch((err) => {
          console.error(err);
          setSubmitting(false);
        });
    }
  };

  const validateForm = (form) => {
    const inv = [];

    (form.startTime !== null && form.startTime !== undefined) ||
      inv.push({
        index: 0,
        key: "startTime",
        msg: "This field cannot be empty",
      });
    (form.location !== null && form.location !== undefined) ||
      inv.push({
        index: 0,
        key: "location",
        msg: "This field cannot be empty",
      });
    (form.duration !== null && form.duration !== undefined) ||
      inv.push({
        index: 0,
        key: "duration",
        msg: "This field cannot be empty",
      });

    const locationOfMediation = form.locationOfMediation;

    const locationOfMediationValid =
      (locationOfMediation !== null && locationOfMediation !== undefined) ||
      (form.location !== "In Person (Other)" &&
        form.location !== "Hybrid (Zoom/In Person)");
    const locationNeeded =
      form.location === "In Person (Other)" ||
      form.location === "Hybrid (Zoom/In Person)";

    if (locationNeeded) {
      (locationOfMediationValid && !isBlank(locationOfMediation.address)) ||
        inv.push({
          index: 0,
          key: "locationAddress",
          msg: "This field cannot be empty",
        });
      (locationOfMediationValid && !isBlank(locationOfMediation.city)) ||
        inv.push({
          index: 0,
          key: "locationCity",
          msg: "This field cannot be empty",
        });
      (locationOfMediationValid && !isBlank(locationOfMediation.state)) ||
        inv.push({
          index: 0,
          key: "locationState",
          msg: "This field cannot be empty",
        });
      (locationOfMediationValid &&
        !isBlank(locationOfMediation.zip) &&
        /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(locationOfMediation.zip)) ||
        inv.push({
          index: 0,
          key: "locationZip",
          msg: "This field has incorrect format",
        });
    }

    const caseInformation = form.caseInformation;
    !isBlank(caseInformation?.caseName) ||
      inv.push({
        index: 0,
        key: "caseName",
        msg: "This field cannot be empty",
      });
    !isBlank(caseInformation?.caseNumber) ||
      inv.push({
        index: 0,
        key: "caseNumber",
        msg: "This field cannot be empty",
      });
    !isBlank(caseInformation?.caseCourt) ||
      inv.push({
        index: 0,
        key: "caseCourt",
        msg: "This field cannot be empty",
      });

    const schedulerInformation = form.schedulerInformation;
    !isBlank(schedulerInformation?.schedulerName) ||
      inv.push({
        index: 0,
        key: "schedulerName",
        msg: "This field cannot be empty",
      });
    (!isBlank(schedulerInformation?.schedulerEmail) &&
      validateEmail(schedulerInformation?.schedulerEmail)) ||
      inv.push({
        index: 0,
        key: "schedulerEmail",
        msg: "This field has incorrect format",
      });

    const counsels = form.counsels;
    if (counsels === null || counsels === undefined || counsels?.length === 0) {
      counsels.push({});
    }

    counsels?.forEach((councel, index) => {
      !isBlank(councel?.represent) ||
        inv.push({
          index,
          key: "councelRepresent",
          msg: "This field cannot be empty",
        });
      !isBlank(councel?.fullName) ||
        inv.push({
          index,
          key: "councelFullName",
          msg: "This field cannot be empty",
        });
      !isBlank(councel?.attorneyName) ||
        inv.push({
          index,
          key: "councelAttorneyName",
          msg: "This field cannot be empty",
        });
      !councel?.attorneyEmail ||
        validateEmail(councel?.attorneyEmail) ||
        inv.push({
          index,
          key: "councelAttorneyName",
          msg: "This field cannot be empty",
        });
      !isBlank(councel.firm_or_organization) ||
        inv.push({
          index,
          key: "councelFirmOrOrganization",
          msg: "This field cannot be empty",
        });
      !isBlank(councel.address) ||
        inv.push({
          index,
          key: "councelAddress",
          msg: "This field cannot be empty",
        });
      isBlank(councel.zip) ||
        /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(councel.zip) ||
        inv.push({
          index,
          key: "councelZip",
          msg: "This field has incorrect format",
        });
      !isBlank(councel.telephone) ||
        inv.push({
          index,
          key: "councelTelephone",
          msg: "This field cannot be empty",
        });
    });

    setInvalid(inv);
    return inv.length === 0;
  };

  const isBlank = (str) => {
    return !str || /^\s*$/.test(str);
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const removeCounselForm = (index) => {
    const newCouncels = { ...counsels };
    delete newCouncels[index];
    setCounsels(newCouncels);
    setCounselsIndeces(counselsIndeces.filter((it) => it !== index));
  };

  function getNextMonthTimestamp(inputTimestamp) {
    // Create a new Date object using the input timestamp
    const currentDate = new Date(inputTimestamp);

    // Get the current month (0-indexed, where January is 0 and December is 11)
    const currentMonth = currentDate.getMonth();

    // Calculate the next month
    const nextMonth = (currentMonth + 1) % 12; // Using modulo to handle year transitions

    // Get the year for the next month (handle year transition if necessary)
    const nextYear =
      currentDate.getFullYear() + Math.floor((currentMonth + 1) / 12);

    // Create a new Date object for the first day of the next month
    const nextMonthDate = new Date(nextYear, nextMonth);

    // Return the timestamp for the first day of the next month
    return nextMonthDate.getTime();
  }

  const addCounselForm = (index) => {
    return (
      <div key={index}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h5 style={{ color: "rgb(7, 73, 115)" }}>Counsel:</h5>
          {index > 1 && (
            <div
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => removeCounselForm(index)}
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="26px"
                height="26px"
                viewBox="0,0,256,256"
              >
                <g
                  fill="#074973"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth="1"
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  strokeMiterlimit="10"
                  strokeDasharray=""
                  strokeDashoffset="0"
                  fontFamily="none"
                  fontWeight="none"
                  fontSize="none"
                  textAnchor="none"
                >
                  <g transform="scale(9.84615,9.84615)">
                    <path d="M13.5,3.1875c-5.69531,0 -10.3125,4.61719 -10.3125,10.3125c0,5.69531 4.61719,10.3125 10.3125,10.3125c5.69531,0 10.3125,-4.61719 10.3125,-10.3125c0,-5.69531 -4.61719,-10.3125 -10.3125,-10.3125zM19,15h-11v-3h11z"></path>
                  </g>
                </g>
              </svg>
            </div>
          )}
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "23%",
            }}
          >
            <label>
              Represent <span style={{ color: "red" }}>*</span>
            </label>
            <SelectPicker
              disabled={submitting}
              searchable={false}
              data={[
                { label: "Plaintiff", value: "Plaintiff" },
                { label: "Defendant", value: "Defendant" },
                { label: "Other", value: "Other" },
              ]}
              onChange={(e) =>
                setCounsels({
                  ...counsels,
                  [index]: {
                    ...(counsels[index] ?? {}),
                    represent: e,
                  },
                })
              }
            />
            <div className="form-error">
              {invalid.find(
                (it) => it.key === "councelRepresent" && it.index === index
              )?.msg ?? ""}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "23%",
            }}
          >
            <label>
              Full Name <span style={{ color: "red" }}>*</span>
            </label>
            <Input
              disabled={submitting}
              placeholder="Plaintiff's/Defendant's name"
              onChange={(e) =>
                setCounsels({
                  ...counsels,
                  [index]: {
                    ...(counsels[index] ?? {}),
                    fullName: e,
                  },
                })
              }
            />
            <div className="form-error">
              {invalid.find(
                (it) => it.key === "councelFullName" && it.index === index
              )?.msg ?? ""}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "23%",
            }}
          >
            <label>
              Attorneys Name <span style={{ color: "red" }}>*</span>
            </label>
            <Input
              disabled={submitting}
              placeholder="Enter attorneys name"
              onChange={(e) =>
                setCounsels({
                  ...counsels,
                  [index]: {
                    ...(counsels[index] ?? {}),
                    attorneyName: e,
                  },
                })
              }
            />
            <div className="form-error">
              {invalid.find(
                (it) => it.key === "councelAttorneyName" && it.index === index
              )?.msg ?? ""}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "23%",
            }}
          >
            <label>Attorneys E-mail </label>
            <Input
              disabled={submitting}
              placeholder="Enter attorneys e-mail address"
              onChange={(e) =>
                setCounsels({
                  ...counsels,
                  [index]: {
                    ...(counsels[index] ?? {}),
                    attorneyEmail: e,
                  },
                })
              }
            />
            <div className="form-error">
              {invalid.find(
                (it) => it.key === "councelAttorneyEmail" && it.index === index
              )?.msg ?? ""}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "23%",
            }}
          >
            <label>Additional E-mails </label>
            <Input
              disabled={submitting}
              placeholder="Enter additional e-mail addresses"
              onChange={(e) =>
                setCounsels({
                  ...counsels,
                  [index]: {
                    ...(counsels[index] ?? {}),
                    additionalEmails: e,
                  },
                })
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "23%",
            }}
          >
            <label>
              Firm/Organization <span style={{ color: "red" }}>*</span>
            </label>
            <Input
              disabled={submitting}
              placeholder="Enter your firm/organization"
              onChange={(e) =>
                setCounsels({
                  ...counsels,
                  [index]: {
                    ...(counsels[index] ?? {}),
                    firm_or_organization: e,
                  },
                })
              }
            />
            <div className="form-error">
              {invalid.find(
                (it) =>
                  it.key === "councelFirmOrOrganization" && it.index === index
              )?.msg ?? ""}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "23%",
            }}
          >
            <label>
              Address <span style={{ color: "red" }}>*</span>
            </label>
            <Input
              disabled={submitting}
              placeholder="Enter address location"
              onChange={(e) =>
                setCounsels({
                  ...counsels,
                  [index]: {
                    ...(counsels[index] ?? {}),
                    address: e,
                  },
                })
              }
            />
            <div className="form-error">
              {invalid.find(
                (it) => it.key === "councelAddress" && it.index === index
              )?.msg ?? ""}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "23%",
            }}
          >
            <label>City</label>
            <Input
              disabled={submitting}
              placeholder="Enter city location"
              onChange={(e) =>
                setCounsels({
                  ...counsels,
                  [index]: {
                    ...(counsels[index] ?? {}),
                    city: e,
                  },
                })
              }
            />
            <div className="form-error"></div>
          </div>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "23%",
            }}
          >
            <label>State</label>
            <SelectPicker
              disabled={submitting}
              searchable={false}
              data={STATES}
              onChange={(e) =>
                setCounsels({
                  ...counsels,
                  [index]: {
                    ...(counsels[index] ?? {}),
                    state: e,
                  },
                })
              }
            />
            <div className="form-error"></div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "23%",
            }}
          >
            <label>Zip</label>
            <Input
              disabled={submitting}
              placeholder="Enter zip code"
              onChange={(e) =>
                setCounsels({
                  ...counsels,
                  [index]: {
                    ...(counsels[index] ?? {}),
                    zip: e,
                  },
                })
              }
            />
            <div className="form-error">
              {invalid.find(
                (it) => it.key === "councelZip" && it.index === index
              )?.msg ?? ""}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "23%",
            }}
          >
            <label>
              Telephone <span style={{ color: "red" }}>*</span>
            </label>
            <Input
              disabled={submitting}
              placeholder="Enter telephone number"
              onChange={(e) =>
                setCounsels({
                  ...counsels,
                  [index]: {
                    ...(counsels[index] ?? {}),
                    telephone: e,
                  },
                })
              }
            />
            <div className="form-error">
              {invalid.find(
                (it) => it.key === "councelTelephone" && it.index === index
              )?.msg ?? ""}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "23%",
            }}
          >
            <label>Insurance Company</label>
            <Input
              disabled={submitting}
              placeholder="Enter Insurance Company"
              onChange={(e) =>
                setCounsels({
                  ...counsels,
                  [index]: {
                    ...(counsels[index] ?? {}),
                    insCompany: e,
                  },
                })
              }
            />
            <div className="form-error"></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <main className="calendar-page">
        {confirmPopup?.type === "eventSuccess" && (
          <Modal
            dialogClassName="success-popup"
            open={confirmPopup?.type === "eventSuccess" ? true : false}
            onClose={() => setConfirmPopup({})}
            backdrop={"static"}
          >
            <Modal.Header>
              <div
                className="rs-notification-title-with-icon"
                style={{
                  display: "flex",
                  gap: "20px",
                  marginBottom: "20px",
                  fontSize: "24px",
                }}
              >
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 14 14"
                  fill="#2acf2a"
                  aria-hidden="true"
                  focusable="false"
                  className="rs-icon"
                  aria-label="check circle"
                  data-category="legacy"
                >
                  <path d="M7 14A7 7 0 117 0a7 7 0 010 14zM5.848 7.702L4.342 6.196a1.166 1.166 0 10-1.65 1.65l2.333 2.333a1.166 1.166 0 001.707-.061l4.61-4.61a1.166 1.166 0 10-1.65-1.65L5.848 7.702z"></path>
                </svg>
                success
              </div>
            </Modal.Header>
            <Modal.Body>
              Event was successfully created:
              <br />
              Consultant: {confirmPopup.consultantName} <br />
              Time: {getTextDate(new Date(confirmPopup.startTime))}{" "}
              {new Date(confirmPopup.startTime).getHours()}:
              {new Date(confirmPopup.startTime).getMinutes()}
            </Modal.Body>
          </Modal>
        )}

        {
          <Modal
            backdrop={"static"}
            open={openAvailConsultantsModal}
            onClose={() => onAvailConsultantClose()}
          >
            <Modal.Header>
              <Modal.Title>
                <div className="modal-subtitle" style={{ margin: "20px 10px" }}>
                  <div className="icon">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      viewBox="0,0,256,256"
                    >
                      <g
                        fill="#070373"
                        fillRule="nonzero"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="10"
                        strokeDasharray=""
                        strokeDashoffset="0"
                        fontFamily="none"
                        fontWeight="none"
                        fontSize="none"
                        textAnchor="none"
                      >
                        <g transform="scale(5.12,5.12)">
                          <path d="M12,0c-1.09375,0 -2,0.90625 -2,2v2h-6c-1.16016,0 -2,0.83984 -2,2v7h46v-7c0,-1.16016 -0.83984,-2 -2,-2h-6v-2c0,-1.09375 -0.90625,-2 -2,-2h-2c-1.09375,0 -2,0.90625 -2,2v2h-18v-2c0,-1.09375 -0.90625,-2 -2,-2zM12,2h2v6h-2zM36,2h2v6h-2zM2,15v31c0,1.16016 0.83984,2 2,2h42c1.16016,0 2,-0.83984 2,-2v-31zM12,21h5v5h-5zM19,21h5v5h-5zM26,21h5v5h-5zM33,21h5v5h-5zM12,28h5v5h-5zM19,28h5v5h-5zM26,28h5v5h-5zM33,28h5v5h-5zM12,35h5v5h-5zM19,35h5v5h-5zM26,35h5v5h-5zM33,35h5v5h-5z"></path>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="modal-subtitle-text">
                    {getTextDate(selectedDate)}
                  </div>
                </div>
                <div style={{ margin: "20px 10px" }}>
                  <h3>Please select available neutral</h3>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                className="modal-available-list"
                style={{ margin: "20px 10px" }}
              >
                {!beforModalLoading &&
                  availConsultants &&
                  availConsultants.map((it, i) => {
                    return (
                      <button
                        key={i}
                        onClick={() => onAvailConsultantClose(it)}
                        className="modal-avail-btn"
                      >
                        {it.name === 'Rodrik David' ? 'Mediation NOW' : it.name}
                      </button>
                    );
                  })}
                {beforModalLoading && <Loader size="md" />}
              </div>
            </Modal.Body>
          </Modal>
        }

        {
          <Modal
            backdrop={"static"}
            overflow={true}
            open={openFormModal}
            onClose={() => onFormClose()}
            size="lg"
          >
            <Modal.Header>
              <Modal.Title>
                <div>
                  <h3 style={{ margin: "20px 10px" }}>
                    You Are Scheduling Mediation With
                  </h3>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "30px",
                    margin: "20px 10px",
                  }}
                >
                  <div className="modal-subtitle">
                    <div className="icon">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="34px"
                        viewBox="0,0,256,256"
                      >
                        <g
                          fill="#2acf2a"
                          fillRule="nonzero"
                          stroke="none"
                          strokeWidth="1"
                          strokeLinecap="butt"
                          strokeLinejoin="miter"
                          strokeMiterlimit="10"
                          strokeDasharray=""
                          strokeDashoffset="0"
                          fontFamily="none"
                          fontWeight="none"
                          fontSize="none"
                          textAnchor="none"
                        >
                          <g transform="scale(2,2)">
                            <path d="M64,37c-12.70255,0 -23,10.29745 -23,23c0,12.70255 10.29745,23 23,23c12.70255,0 23,-10.29745 23,-23c0,-12.70255 -10.29745,-23 -23,-23zM64,91c-15,0 -29.1,7.39922 -37.5,19.69922c-1.7,2.5 -2.5,5.40039 -2.5,8.40039c0,1.6 1.4,2.90039 3,2.90039h74c1.6,0 3,-1.30039 3,-2.90039c0.1,-3 -0.8,-6.00039 -2.5,-8.40039c-8.4,-12.3 -22.5,-19.69922 -37.5,-19.69922z"></path>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div
                      className="modal-subtitle-text"
                      style={{
                        color: "#2ACF2A",
                        marginTop: 0,
                      }}
                    >
                      {selectedConsultant?.name}
                    </div>
                  </div>
                  <div className="modal-subtitle">
                    <div className="icon">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        viewBox="0,0,256,256"
                      >
                        <g
                          fill="#070373"
                          fillRule="nonzero"
                          stroke="none"
                          strokeWidth="1"
                          strokeLinecap="butt"
                          strokeLinejoin="miter"
                          strokeMiterlimit="10"
                          strokeDasharray=""
                          strokeDashoffset="0"
                          fontFamily="none"
                          fontWeight="none"
                          fontSize="none"
                          textAnchor="none"
                        >
                          <g transform="scale(5.12,5.12)">
                            <path d="M12,0c-1.09375,0 -2,0.90625 -2,2v2h-6c-1.16016,0 -2,0.83984 -2,2v7h46v-7c0,-1.16016 -0.83984,-2 -2,-2h-6v-2c0,-1.09375 -0.90625,-2 -2,-2h-2c-1.09375,0 -2,0.90625 -2,2v2h-18v-2c0,-1.09375 -0.90625,-2 -2,-2zM12,2h2v6h-2zM36,2h2v6h-2zM2,15v31c0,1.16016 0.83984,2 2,2h42c1.16016,0 2,-0.83984 2,-2v-31zM12,21h5v5h-5zM19,21h5v5h-5zM26,21h5v5h-5zM33,21h5v5h-5zM12,28h5v5h-5zM19,28h5v5h-5zM26,28h5v5h-5zM33,28h5v5h-5zM12,35h5v5h-5zM19,35h5v5h-5zM26,35h5v5h-5zM33,35h5v5h-5z"></path>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="modal-subtitle-text">
                      {getTextDate(selectedDate)}
                    </div>
                  </div>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form style={{ margin: "10px 10px", width: "100%" }}>
                <div style={{ display: "flex", gap: "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "23%",
                    }}
                  >
                    <label>
                      Start time <span style={{ color: "red" }}>*</span>
                    </label>
                    {filteredSlots && 
                      filteredSlots?.length !== 0 && (
                      <SelectPicker
                        disabled={submitting}
                        searchable={false}
                        data={filteredSlots}
                        onChange={(e) => {
                          calculateMaxTimeDuration(e);
                          setForm({
                            ...form,
                            startTime: e,
                          });
                        }}
                      />
                      )
                    }
                    <div className="form-error">
                      {invalid.find((it) => it.key === "startTime")?.msg ?? ""}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "23%",
                    }}
                  >
                    <label>
                      Expected Duration <span style={{ color: "red" }}>*</span>
                    </label>
                    {!availSlotsFlag && (
                      <SelectPicker
                        disabled={submitting}
                        searchable={false}
                        cleanable={false}
                        data={maxDurationSlots}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            duration: e,
                          })
                        }
                      />
                    )}
                    {availSlotsFlag && (
                      <SelectPicker
                        disabled={submitting}
                        searchable={false}
                        cleanable={false}
                        data={maxDurationSlots}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            duration: e,
                          })
                        }
                      />
                    )}
                    <div className="form-error">
                      {invalid.find((it) => it.key === "duration")?.msg ?? ""}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "23%",
                    }}
                  >
                    <label>
                      Location <span style={{ color: "red" }}>*</span>
                    </label>
                    {selectedConsultant?.name === "Mediation NOW" && (
                      <SelectPicker
                        disabled={submitting}
                        searchable={false}
                        data={[
                          {
                            label: "Remote",
                            value: "Remote",
                          },
                        ]}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            location: e,
                          })
                        }
                      />
                    )}
                    {selectedConsultant?.name !== "Mediation NOW" && (
                      <SelectPicker
                        disabled={submitting}
                        searchable={false}
                        data={[
                          {
                            label: "Remote",
                            value: "Remote",
                          },
                          {
                            label: "In Person (Sugarloaf ADR Offices)",
                            value: "In Person (Sugarloaf ADR Offices)",
                          },
                          {
                            label: "In Person (Other)",
                            value: "In Person (Other)",
                          },
                          {
                            label: "Hybrid (Zoom/In Person)",
                            value: "Hybrid (Zoom/In Person)",
                          },
                        ]}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            location: e,
                          })
                        }
                      />
                    )}

                    <div className="form-error">
                      {invalid.find((it) => it.key === "location")?.msg ?? ""}
                    </div>
                  </div>
                </div>
                {(form.location === "In Person (Other)" ||
                  form.location === "Hybrid (Zoom/In Person)") && (
                  <>
                    <h5 style={{ color: "rgb(7, 73, 115)" }}>
                      Location of Mediation:
                    </h5>
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "23%",
                        }}
                      >
                        <label>
                          Address <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          disabled={submitting}
                          placeholder="Enter address location"
                          onChange={(e) =>
                            setForm({
                              ...form,
                              locationOfMediation: {
                                ...(form?.locationOfMediation ?? {}),
                                address: e,
                              },
                            })
                          }
                        />
                        <div className="form-error">
                          {invalid.find((it) => it.key === "locationAddress")
                            ?.msg ?? ""}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "23%",
                        }}
                      >
                        <label>
                          City <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          disabled={submitting}
                          placeholder="Enter city location"
                          onChange={(e) =>
                            setForm({
                              ...form,
                              locationOfMediation: {
                                ...(form?.locationOfMediation ?? {}),
                                city: e,
                              },
                            })
                          }
                        />
                        <div className="form-error">
                          {invalid.find((it) => it.key === "locationCity")
                            ?.msg ?? ""}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "23%",
                        }}
                      >
                        <label>
                          State <span style={{ color: "red" }}>*</span>
                        </label>
                        <SelectPicker
                          disabled={submitting}
                          searchable={false}
                          data={STATES}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              locationOfMediation: {
                                ...(form?.locationOfMediation ?? {}),
                                state: e,
                              },
                            })
                          }
                        />
                        <div className="form-error">
                          {invalid.find((it) => it.key === "locationState")
                            ?.msg ?? ""}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "23%",
                        }}
                      >
                        <label>
                          Zip <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          disabled={submitting}
                          placeholder="Enter zip code"
                          onChange={(e) =>
                            setForm({
                              ...form,
                              locationOfMediation: {
                                ...(form?.locationOfMediation ?? {}),
                                zip: e,
                              },
                            })
                          }
                        />
                        <div className="form-error">
                          {invalid.find((it) => it.key === "locationZip")
                            ?.msg ?? ""}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <h5 style={{ color: "rgb(7, 73, 115)" }}>Case Information:</h5>
                <div style={{ display: "flex", gap: "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "23%",
                    }}
                  >
                    <label>
                      Case Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <Input
                      disabled={submitting}
                      placeholder="Enter case name"
                      onChange={(e) =>
                        setForm({
                          ...form,
                          caseInformation: {
                            ...(form?.caseInformation ?? {}),
                            caseName: e,
                          },
                        })
                      }
                    />
                    <div className="form-error">
                      {invalid.find((it) => it.key === "caseName")?.msg ?? ""}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "23%",
                    }}
                  >
                    <label>
                      Case Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <Input
                      disabled={submitting}
                      placeholder="Enter case number"
                      onChange={(e) =>
                        setForm({
                          ...form,
                          caseInformation: {
                            ...(form?.caseInformation ?? {}),
                            caseNumber: e,
                          },
                        })
                      }
                    />
                    <div className="form-error">
                      {invalid.find((it) => it.key === "caseNumber")?.msg ?? ""}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "23%",
                    }}
                  >
                    <label>
                      Case Court <span style={{ color: "red" }}>*</span>
                    </label>
                    <Input
                      disabled={submitting}
                      placeholder="Enter case court"
                      onChange={(e) =>
                        setForm({
                          ...form,
                          caseInformation: {
                            ...(form?.caseInformation ?? {}),
                            caseCourt: e,
                          },
                        })
                      }
                    />
                    <div className="form-error">
                      {invalid.find((it) => it.key === "caseCourt")?.msg ?? ""}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "23%",
                    }}
                  >
                    <label>
                      Court Type <span style={{ color: "red" }}>*</span>
                    </label>
                    <SelectPicker
                      defaultValue="Voluntary"
                      cleanable={false}
                      disabled={submitting}
                      searchable={false}
                      data={[
                        {
                          label: "Voluntary",
                          value: "Voluntary",
                        },
                        {
                          label: "Court Ordered",
                          value: "Court Ordered",
                        },
                      ]}
                      onChange={(e) =>
                        setForm({
                          ...form,
                          caseInformation: {
                            ...(form?.caseInformation ?? {}),
                            courtType: e,
                          },
                        })
                      }
                    />
                    <div className="form-error"></div>
                  </div>
                </div>
                {form?.caseInformation?.courtType === "Court Ordered" && (
                  <div style={{ display: "flex", gap: "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        width: "23%",
                      }}
                    >
                      <label>
                        Court Ordered <span style={{ color: "red" }}>*</span>
                      </label>
                      <DatePicker
                        disabled={submitting}
                        defaultValue={selectedDate}
                        placeholder="Choose data"
                        onChange={(e) =>
                          setForm({
                            ...form,
                            caseInformation: {
                              ...(form?.caseInformation ?? {}),
                              courtOrdered: e,
                            },
                          })
                        }
                      />
                      <div className="form-error">
                        {invalid.find((it) => it.key === "courtOrdered")?.msg ??
                          ""}
                      </div>
                    </div>
                  </div>
                )}

                <h5 style={{ color: "rgb(7, 73, 115)" }}>
                  Scheduler Information:
                </h5>
                <div style={{ display: "flex", gap: "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "23%",
                    }}
                  >
                    <label>
                      Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <Input
                      disabled={submitting}
                      placeholder="Enter scheduler name"
                      onChange={(e) =>
                        setForm({
                          ...form,
                          schedulerInformation: {
                            ...(form?.schedulerInformation ?? {}),
                            schedulerName: e,
                          },
                        })
                      }
                    />
                    <div className="form-error">
                      {invalid.find((it) => it.key === "schedulerName")?.msg ??
                        ""}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "23%",
                    }}
                  >
                    <label>
                      Email <span style={{ color: "red" }}>*</span>
                    </label>
                    <Input
                      disabled={submitting}
                      placeholder="Enter scheduler email"
                      onChange={(e) =>
                        setForm({
                          ...form,
                          schedulerInformation: {
                            ...(form?.schedulerInformation ?? {}),
                            schedulerEmail: e,
                          },
                        })
                      }
                    />
                    <div className="form-error">
                      {invalid.find((it) => it.key === "schedulerEmail")?.msg ??
                        ""}
                    </div>
                  </div>
                </div>

                {counselsIndeces.map((it) => {
                  return addCounselForm(it);
                })}
                <h5 style={{ color: "rgb(7, 73, 115)" }}>
                  Add Additional Counsel:
                </h5>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setCounselsIndeces([
                      ...counselsIndeces,
                      counselsIndeces.length,
                    ]);
                    setCounsels({
                      ...counsels,
                      [counselsIndeces.length]: {},
                    });
                  }}
                  style={{
                    width: "100px",
                    height: "100px",
                    background: "#E6E6E6",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="50"
                    height="50"
                    viewBox="0,0,256,256"
                  >
                    <g
                      fill="#2acf2a"
                      fillRule="nonzero"
                      stroke="none"
                      strokeWidth="1"
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      strokeMiterlimit="10"
                      strokeDasharray=""
                      strokeDashoffset="0"
                      fontFamily="none"
                      fontWeight="none"
                      fontSize="none"
                      textAnchor="none"
                    >
                      <g transform="scale(5.12,5.12)">
                        <path d="M25,2c-12.683,0 -23,10.317 -23,23c0,12.683 10.317,23 23,23c12.683,0 23,-10.317 23,-23c0,-12.683 -10.317,-23 -23,-23zM37,26h-11v11h-2v-11h-11v-2h11v-11h2v11h11z"></path>
                      </g>
                    </g>
                  </svg>
                </button>
                <h5 style={{ color: "rgb(7, 73, 115)" }}>
                  Additional Information:
                </h5>
                <textarea
                  disabled={submitting}
                  maxLength={2000}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      additionalInformation: e.target.value,
                    })
                  }
                  style={{
                    width: "100%",
                    padding: "10px",
                    height: "150px",
                    border: "#E6E6E6 1px solid",
                  }}
                ></textarea>
              </form>
            </Modal.Body>
            <Modal.Footer>
              {
                <div
                  style={{
                    display: "flex",
                    paddingTop: "30px",
                  }}
                >
                  {submitting || (
                    <Button appearance="primary" onClick={(e) => submitForm(e)}>
                      SUBMIT
                    </Button>
                  )}
                  {submitting && <Loader size="md" />}
                </div>
              }
            </Modal.Footer>
          </Modal>
        }

        <h3>
          Please Click the Calendar to Check Your Preferred Available Date
        </h3>
        
        <div className="calendar-container">
          <div className='left-container'>
          <div className="left">
            <h5>General Civil - Insurance Coverage -<br />Personal Injury - Bad Faith</h5>
            <br />
            <div className="select-panel">
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  disabled={calendarLoading}
                  checked={checkedAll}
                  onChange={(e) => select(e.target.checked, "all")}
                />
                <span className="checkbox-checkmark"></span>
                <span style={{ fontWeight: "700" }}>Checked all</span>
              </label>
              {consultants &&
                consultants.map((it, i) => {
                  return (
                    <label key={i} className="checkbox-container">
                      <input
                        type="checkbox"
                        disabled={calendarLoading}
                        checked={selected.some((item) => item.consultant_id === it.consultant_id)}
                        onChange={(e) => select(e.target.checked, it)}
                      />
                      <span className="checkbox-checkmark"></span>
                      {it.name}
                    </label>
                  );
                })}
              {!consultants && <Loader size="lg" />}
            </div>
          </div>
           <div className="left">
            <h5>General Civil - Product Liability - Medical Malpractice Automobile & Trucking - Aviation - Wrongful Death</h5>
            <br />
            <div className="select-panel">
            {/* <label className="checkbox-container">
                <input
                  type="checkbox"
                  disabled={calendarLoading}
                  // checked={selected.some((item) => item.consultant_id === it.consultant_id)}
                  // onChange={(e) => select(e.target.checked, it)}
                />
                <span className="checkbox-checkmark"></span>
                Test General Civil
              </label> */}
              {/* {businessConsultants &&
                businessConsultants.map((it, i) => {
                  return (
                    <label key={i} className="checkbox-container">
                      <input
                        type="checkbox"
                        disabled={calendarLoading}
                        checked={selected.some((item) => item.consultant_id === it.consultant_id)}
                        onChange={(e) => select(e.target.checked, it)}
                      />
                      <span className="checkbox-checkmark"></span>
                      {it.name}
                    </label>
                  );
                })}
              {!businessConsultants && <Loader size="lg" />} */}
            </div>
          </div>

          <div className="left">
            <h5>Business&Commercial:</h5>
            <br />
            <div className="select-panel">
              {businessConsultants &&
                businessConsultants.map((it, i) => {
                  return (
                    <label key={i} className="checkbox-container">
                      <input
                        type="checkbox"
                        disabled={calendarLoading}
                        checked={selected.some((item) => item.consultant_id === it.consultant_id)}
                        onChange={(e) => select(e.target.checked, it)}
                      />
                      <span className="checkbox-checkmark"></span>
                      {it.name}
                    </label>
                  );
                })}
              {!businessConsultants && <Loader size="lg" />}
            </div>
          </div>
          </div>
          <div className={"right " + (calendarLoading ? "loading" : "")}>
            {calendarLoading && <Loader size="lg" />}
            {
              <div
                className="calendar-inner-container"
                style={{
                  display: calendarLoading ? "none" : "flex",
                }}
              >
                <div className="calendar-hidden-today">
                  <Calendar
                    bordered
                    compact
                    onChange={(date) => cellChange(date, 1)}
                    renderCell={(date) => renderCell(date, 1)}
                    onSelect={(date) => cellSelect(date, 1)}
                    onMonthChange={(date) => cellMonthChange(date, 1)}
                  />
                </div>
                <div className="calendar-hidden-btns">
                  <Calendar
                    bordered
                    compact
                    value={secondCalendarValue}
                    onChange={(date) => cellChange(date, 2)}
                    renderCell={(date) => renderCell(date, 2)}
                    onSelect={(date) => cellSelect(date, 2)}
                    onMonthChange={(date) => cellMonthChange(date, 2)}
                  />
                </div>
              </div>
            }
          </div>
        </div>
        <div className='calendar-error-massage'>
        If you have any issues scheduling with our website please reach out to us at <a href="tel:678-730-7500">
        678-730-7500</a> or email us at <a href="mailto:mediation@sugarloafadr.com">mediation@sugarloafadr.com</a>. Thank you!
        </div>
      </main>
      <Footer />
    </>
  );
}
